// Buttons

// Global styles

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.btn-accent {
  --#{$prefix}-btn-hover-bg: #{$primary};
  --#{$prefix}-btn-hover-border-color: #{$primary};
  &:hover {
    background-color: var(--#{$prefix}-btn-hover-bg);
    border-color: var(--#{$prefix}-btn-hover-border-color);
  }
}


// Link button

.btn-link {
  text-decoration: $btn-link-decoration;
  &:hover {
    text-decoration: $btn-link-hover-decoration;
  }
}


// Scroll to top button

.btn-scroll-top {
  --#{$prefix}btn-scroll-top-size: #{$btn-scroll-top-size};
  --#{$prefix}btn-scroll-top-border-radius: 50%;
  --#{$prefix}btn-scroll-top-color: #{$btn-scroll-top-color};
  --#{$prefix}btn-scroll-top-hover-color: #{$btn-scroll-top-hover-color};
  --#{$prefix}btn-scroll-top-bg: #{$btn-scroll-top-bg};
  --#{$prefix}btn-scroll-top-hover-bg: #{$btn-scroll-top-hover-bg};
  --#{$prefix}btn-scroll-top-icon-size: #{$btn-scroll-top-icon-font-size};

  position: fixed;
  right: $spacer * 1.25;
  bottom: -($btn-scroll-top-size * 1.5);
  z-index: $zindex-fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--#{$prefix}btn-scroll-top-size);
  height: var(--#{$prefix}btn-scroll-top-size);
  color: var(--#{$prefix}btn-scroll-top-color);
  text-decoration: none;
  background-color: var(--#{$prefix}btn-scroll-top-bg);
  opacity: 0;
  transition: $btn-scroll-top-transition;
  @include border-radius(var(--#{$prefix}btn-scroll-top-border-radius));
  > .btn-scroll-top-icon {
    font: {
      size: var(--#{$prefix}btn-scroll-top-icon-size);
      weight: bold;
    }
  }

  .btn-scroll-top-tooltip {
    position: absolute;
    top: 50%;
    right: 100%;
    visibility: hidden;
    opacity: 0;
    transition: opacity .25s ease-in-out, visibility .25s ease-in-out;
    transform: translateY(-50%);
  }

  &:hover {
    color: var(--#{$prefix}btn-scroll-top-hover-color);
    background-color: var(--#{$prefix}btn-scroll-top-hover-bg);
    .btn-scroll-top-tooltip {
      visibility: visible;
      opacity: 1;
    }
  }

  &.show {
    bottom: $spacer * 1.25;
    opacity: 1;
  }

  @include media-breakpoint-down(sm) {
    right: $spacer;
    width: calc(var(--#{$prefix}btn-scroll-top-size) * .8);
    height: calc(var(--#{$prefix}btn-scroll-top-size) * .8);
    &.show {
      bottom: $spacer;
    }
  }
}
