// Nav

// Base styles

.nav {
  --#{$prefix}nav-link-active-color: #{$nav-link-active-color};
  --#{$prefix}nav-tabs-font-family: #{$nav-tabs-font-family};
}

.nav-item {
  margin-bottom: 0;
}

.nav-link {
  display: flex;
  align-items: center;
  background: none;
  border: 0;

  &.active {
    color: var(--#{$prefix}nav-link-active-color);
  }
  &.active:not([data-bs-toggle="dropdown"]) {
    pointer-events: none;
    cursor: default;
  }
}


// Tabs and Pills

.nav-tabs,
.nav-pills {
  &.nav-fill .nav-link,
  &.nav-justified .nav-link {
    justify-content: center;
  }
  .dropdown-menu {
    @include border-top-radius(var(--#{$prefix}dropdown-border-radius));
  }
}

.nav-tabs {
  margin-bottom: $spacer;
  .nav-link {
    @include border-radius(var(--#{$prefix}nav-tabs-border-radius));
    font: {
      family: var(--#{$prefix}nav-tabs-font-family);
      weight: $nav-tabs-link-font-weight;
    }
  }
}


// Hover-link block

.link-hover {
  position: relative;

  &:hover .link-hover-icon {
    background-color: rgba($gray-900, .4);

    &::before {
      transform: scale(1);
    }
  }
}
.link-hover-icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $font-size-xl;
  color: $white;
  background-color: transparent;
  transition: background-color .25s ease-in-out;

  &::before {
    transition: transform .25s ease-in-out;
    transform: scale(0);
  }
}
