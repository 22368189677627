// Navbar

// Menu toggler
/* stylelint-disable */
header {
  top: $spacer !important;
  @include media-breakpoint-up(lg) {
    top: $spacer * 2 !important;
  }
  .dropdown-toggle::after{
    color: $accent;
  }
}

.navbar-nav {
  .nav-link {
    position: relative;
    &::before {
      position: absolute;
      bottom: 2px;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 1px;
      background-color: $nav-link-active-color;
      transition: width .2s ease-in-out;
      content: '';
    }
    &:hover,
    &.active {
      &::before {
        width: 100%;
      }
    }
  }
}

.navbar-toggler {
  position: relative;
  width: $navbar-toggler-bar-width + $navbar-toggler-padding-x * 2;
  height: $navbar-toggler-bar-height * 3 + $navbar-toggler-bar-spacing * 2 + $navbar-toggler-padding-y * 2;
}

.navbar-toggler-icon {
  display: block;
  top: 50%;
  margin-top: $navbar-toggler-bar-height * -.5;
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

  &, &::before, &::after {
    position: absolute;
    width: $navbar-toggler-bar-width;
    height: $navbar-toggler-bar-height;
    transition-property: transform;
    background-color: $gray-900;
  }
  &::before, &::after {
    display: block;
    width: $navbar-toggler-bar-width * .7;
    content: '';
  }
  &:after {
    right: 0;
  }
  &::before {
    top: ($navbar-toggler-bar-spacing + $navbar-toggler-bar-height) * -1;
    transition: top 0.075s 0.12s ease, opacity 0.075s ease, width .15s ease-in-out;
  }
  &::after {
    bottom: ($navbar-toggler-bar-spacing + $navbar-toggler-bar-height) * -1;
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19), width .15s ease-in-out;
  }
}

.navbar-toggler:hover .navbar-toggler-icon {
  &, &::before, &::after {
    width: $navbar-toggler-bar-width;
  }
}

[aria-expanded=true] {
  .navbar-toggler-icon {
    transform: rotate(45deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    &::before, &::after {
      width: $navbar-toggler-bar-width;
    }
    &::before {
      top: 0;
      opacity: 0;
      transition: top 0.075s ease, opacity 0.075s 0.12s ease;
    }
    &::after {
      bottom: 0;
      transform: rotate(-90deg);
      transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    }
  }
}
/* stylelint-enable */
