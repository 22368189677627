// Utilities
// Extends default Bootstrap utility classes
// Classes with a purpose to reduce the frequency of
// highly repetitive declarations


@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

$utilities: map-merge(
  $utilities,
  (
    // Border opacity
    "border-opacity": (
      css-var: true,
      class: border-opacity,
      values: (
        10: .1,
        20: .2,
        25: .25,
        50: .5,
        75: .75
      )
    ),
    // Background size
    "background-size": (
      property: background-size,
      class: bg-size,
      values: (
        cover: cover,
        contain: contain
      )
    ),
    // Background position
    "background-position": (
      property: background-position,
      class: bg-position,
      values: (
        "center": center,
        "center-y": left center,
        "center-x": center top,
        "top-end": top right,
        "top-start": top left,
        "center-end": center right,
        "center-start": center left,
        "center-bottom": center bottom,
        "bottom-end": bottom right,
        "bottom-start": bottom left,
        "bottom-center": bottom center
      )
    ),
    // Background repeat
    "background-repeat": (
      property: background-repeat,
      class: bg-repeat,
      values: (
        0: no-repeat,
        x: repeat-x,
        y: repeat-y
      )
    ),
    // Z-index
    "z-index": (
      property: z-index,
      class: zindex,
      values: 1 2 3 4 5
    ),
    // Width
    "width": (
      property: width,
      responsive: true,
      class: w,
      values: (
        25: 25%,
        50: 50%,
        100: 100%,
        auto: auto
      )
    ),
    // Height
    "height": (
      property: height,
      responsive: true,
      class: h,
      values: (
        50: 50%,
        100: 100%,
        auto: auto
      )
    ),
    // Opacity
    "opacity": (
      property: opacity,
      class: opacity,
      values: (
        0: 0,
        50: .5,
        100: 1
      )
    ),
  )
);

/* stylelint-disable declaration-no-important */
// Position
.position-md-absolute {
  @include media-breakpoint-up(md) {
    position: absolute;
  }
}

// Negative gutter
.mx-ngutter {
  margin: {
    right: -($grid-gutter-width * .5);
    left: -($grid-gutter-width * .5);
  }
}

// Disable background-image
.bg-image-none {
  background-image: none !important;
}
/* stylelint-enable declaration-no-important */
