// Carousel
// Based on https://github.com/ganlanyuan/tiny-slider


// Base style

.tns-carousel-wrapper {
  position: relative;

  /* stylelint-disable declaration-no-important */
  .tns-carousel-inner {
    position: relative;
    display: flex !important;
    overflow: hidden;
    touch-action: manipulation;
    opacity: 0;

    &:active { cursor: grab; }
    &.tns-slider {
      display: block;
      opacity: 1;
    }
    > * { backface-visibility: hidden; }
  }
  /* stylelint-enable declaration-no-important */

  // Carousel nav (dots)
  .tns-nav {
    padding-top: $spacer * 2;
    text-align: center;
    @include media-breakpoint-up(lg) {
      padding-top: $spacer * 3.5;
    }
  }
  [data-nav] {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: $carousel-nav-width;
    height: $carousel-nav-width;
    margin: {
      right: $spacer * .25;
      left: $spacer * .25;
    }
    padding: 0;
    background-color: transparent;
    border: 0;
    &::before {
      display: block;
      width: $carousel-nav-width * .5;
      height: $carousel-nav-height * .5;
      content: "";
      background-color: $carousel-nav-bg;
      transition: background-color .25s ease-in-out;
      transform: rotate(45deg);
    }
    &:focus { outline: none; }
    &.tns-nav-active::before {
      background-color: $carousel-nav-active-bg;
    }
  }
}
