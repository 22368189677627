// Reboot

// Make the edges of fonts smoother

html * {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Sticky footer

html,
body {
  height: 100%;
}
body {
  display: flex;
  flex-direction: column;
}
.page-wrapper {
  flex: 1 0 auto;
}
.footer {
  flex-shrink: 0;
}


// Images
// Responsive images (ensure images don't scale beyond their parents)

img,
figure {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}
svg {
  max-width: 100%;
}

// Responsive iframes

iframe {
  width: 100%;
}

// Colored links

.link-body-emphasis {
  --#{$prefix}link-opacity: 1;
}

// Text Selection Color

::selection {
  background: var(--#{$prefix}user-selection-color);
}
