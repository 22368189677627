// Dropdown

// Dropdown component animations
@keyframes fade-in  {
  from { opacity: 0; }
  to { opacity: 1; }
}


// Dropdown menu animation
.dropdown-menu.show {
  animation: fade-in .25s ease-in-out;
}
