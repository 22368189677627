// Parallax

/* stylelint-disable declaration-no-important */
[data-disable-parallax-down="md"] {
  @include media-breakpoint-down(md) {
    opacity: 1 !important;
    transform: none !important;
  }
}
[data-disable-parallax-down="lg"] {
  @include media-breakpoint-down(lg) {
    opacity: 1 !important;
    transform: none !important;
  }
}
/* stylelint-enable declaration-no-important */
