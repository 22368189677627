// Forms

.form-control,
.form-select {
  &,
  &:focus {
    border: {
      top-width: 0;
      right-width: 0;
      left-width: 0;
    }
  }
}
