// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-hover-color and $btn-padding-y-sm.

// Import Bootstrap functions to use inside variables values
@import "bootstrap/scss/functions";


// Prefix for CSS variables

$prefix:  vb- !default;


// Color system

// Grayscale

$white:    #fff !default;
$gray-100: #f4eef0 !default;
$gray-200: #f9f2fa !default;
$gray-300: #f3f6fb !default;
$gray-400: #d4d8e2 !default;
$gray-500: #a9b0bc !default;
$gray-600: #d5cdd0 !default;
$gray-700: #8a757e !default;
$gray-800: #684d58 !default;
$gray-900: #452532 !default;
$black:    #000 !default;


// Theme colors

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio:   2 !default;

$primary:       #74344e !default;
$secondary:     $gray-100 !default;
$accent:        #dc93a3 !default;
$success:       #86ab12 !default;
$info:          #5ecef6 !default;
$warning:       #ffa200 !default;
$danger:        #f03d3d !default;
$light:         $white !default;
$dark:          $gray-900 !default;

// Bootstrap's $theme-colors map
$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "accent":     $accent,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
) !default;


// User selection color

$user-selection-color:        rgba($gray-900, .1) !default;


// Gradients

$gradient:                    linear-gradient(180deg, $white, rgba($info, .3)) !default;


// Options
//
// Modifying Bootstrap global options

$enable-chevron:              true !default;
$enable-shadows:              true !default;
$enable-negative-margins:     true !default;
$enable-dark-mode:            false !default;


// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-gutter-width:           1.5rem !default;


// Border-radius

$border-radius:               .375rem !default;
$border-radius-sm:            .25rem !default;
$border-radius-lg:            .5rem !default;
$border-radius-xl:            1rem !default;
$border-radius-xxl:           2rem !default;
$border-radius-pill:          50rem !default;


// Box-shadows

$box-shadow:                  0 .125rem .5rem -.25rem rgba($gray-900, .12), 0 .25rem 1rem rgba($gray-900, .12) !default;
$box-shadow-lg:               0 .25rem .75rem -.375rem rgba($gray-900, .2), 0 .5rem 1rem rgba($gray-900, .16) !default;
$box-shadow-sm:               0 .125rem .125rem -.125rem rgba($gray-900, .08), 0 .25rem .75rem rgba($gray-900, .08) !default;


// Spacing

$spacer:                      1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3
) !default;


// Body
// Settings for the `<body>` element.

$body-color:                  $gray-800 !default;
$body-bg:                     $white !default;

$body-secondary-color:        $gray-700 !default;
$body-secondary-bg:           $gray-100 !default;

$body-tertiary-color:         $gray-600 !default;
$body-tertiary-bg:            $gray-200 !default;

$body-emphasis-color:         $gray-800 !default;


// Override Bootstrap's links

$link-color:                  $gray-800 !default;
$link-hover-color:            $accent !default;
$link-shade-percentage:       0% !default;
$link-decoration:             none !default;
$link-hover-decoration:       none !default;


// Paragraphs

$paragraph-margin-bottom:     $spacer * 1.5 !default;


// Grid breakpoints
//
// Modifying default Bootstrap's $grid-breakpoints map

$grid-breakpoints: (
  xs: 0,
  sm: 500px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
) !default;


// Grid containers
//
// Modifying default Bootstrap's $container-max-widths map (max-width of .container)
$container-max-widths: (
  sm: 95%,
  md: 95%,
  lg: 95%,
  xl: 95%,
  xxl: 1320px
) !default;


// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:      "Roboto", sans-serif !default;
$icons-font-family:           "beauty-icons" !default;
$icons-font-path:             "../fonts" !default;

$font-size-base:              1rem !default;
$font-size-sm:                $font-size-base * .875 !default;
$font-size-lg:                $font-size-base * 1.125 !default;
$font-size-xl:                $font-size-base * 1.25 !default;

$h1-font-size:                $font-size-base * 2.25 !default;
$h2-font-size:                $font-size-base * 1.75 !default;
$h3-font-size:                $font-size-base * 1.25 !default;
$h4-font-size:                $font-size-base * 1.25 !default;
$h5-font-size:                $font-size-base * 1.125 !default;
$h6-font-size:                $font-size-base !default;

// Modifying default Bootstrap's $font-sizes map
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  6: $h6-font-size,
  "xl": $font-size-xl,
  "lg": $font-size-lg,
  "base": $font-size-base,
  "sm": $font-size-sm
) !default;

$display-font-sizes: (
  1: 3.125rem,
  2: 3rem,
  3: 2.875rem,
  4: 2.75rem,
  5: 2.5rem,
  6: 2.25rem
) !default;

$font-weight-lighter:               200 !default;
$font-weight-light:                 300 !default;
$font-weight-normal:                400 !default;
$font-weight-medium:                500 !default;
$font-weight-semibold:              600 !default;
$font-weight-bold:                  700 !default;
$font-weight-bolder:                bolder !default;

$headings-margin-bottom:            $spacer !default;
$headings-font-family:              "Roboto Serif", serif !default;
$headings-line-height:              1.4 !default;
$headings-color:                    $gray-900 !default;
$headings-font-weight:              $font-weight-lighter !default;
$display-font-weight:               $font-weight-lighter !default;


// Buttons + Forms

$input-btn-padding-y:               .576rem !default;
$input-btn-padding-x:               1.5rem !default;
$input-btn-font-size:               $font-size-sm !default;

$input-btn-padding-y-lg:            1rem !default;
$input-btn-padding-x-lg:            2rem !default;
$input-btn-font-size-lg:            $font-size-base !default;


// Buttons

$btn-focus-width:                   0 !default;
$btn-box-shadow:                    none !default;
$btn-focus-box-shadow:              none !default;
$btn-active-box-shadow:             none !default;
$btn-link-color:                    $gray-900 !default;
$btn-link-decoration:               none !default;
$btn-link-hover-color:              $primary !default;
$btn-link-hover-decoration:         none !default;


// Scroll to top button

$btn-scroll-top-size:               2.75rem !default;
$btn-scroll-top-icon-font-size:     $font-size-base * 1.5 !default;
$btn-scroll-top-bg:                 rgba($gray-900, .2) !default;
$btn-scroll-top-hover-bg:           rgba($gray-900, .4) !default;
$btn-scroll-top-color:              $white !default;
$btn-scroll-top-hover-color:        $white !default;
$btn-scroll-top-transition:         bottom 400ms cubic-bezier(.68, -.55, .265, 1.55), opacity .3s, background-color .25s ease-in-out !default;


// Forms

$input-padding-x:                   0 !default;
$input-padding-x-sm:                0 !default;
$input-padding-x-lg:                0 !default;
$input-font-size:                   $font-size-base !default;
$input-bg:                          transparent !default;
$input-disabled-bg:                 transparent !default;
$input-border-radius:               0 !default;
$input-border-radius-sm:            0 !default;
$input-border-radius-lg:            0 !default;
$input-border-color:                $gray-600 !default;
$input-focus-border-color:          $gray-900 !default;
$input-box-shadow:                  0 !default;
$input-focus-box-shadow:            0 !default;
$form-select-box-shadow:            0 !default;
$form-check-input-border:           1px solid $gray-600 !default;
$form-check-input-border-radius:    0 !default;
$form-check-input-focus-box-shadow: 0 !default;


// Badges

$badge-font-size:                   1em !default;
$badge-font-weight:                 $font-weight-normal !default;
$badge-padding-y:                   .25em !default;
$badge-padding-x:                   .75em !default;


// Accordion

$accordion-padding-y:               1.25rem !default;
$accordion-padding-x:               0 !default;
$accordion-bg:                      transparent !default;
$accordion-border-width:            0 !default;
$accordion-border-radius:           0 !default;
$accordion-button-bg:               transparent !default;
$accordion-button-active-bg:        transparent !default;
$accordion-button-focus-box-shadow: none !default;
$accordion-icon-transform:          none !default;
$accordion-icon-width:              1rem !default;
$accordion-icon-color:              $gray-700 !default;
$accordion-icon-active-color:       $gray-700 !default;
$accordion-button-icon:             url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'><path fill-rule='evenodd' d='M7.999.667c.368 0 .667.298.667.667v6h6c.368 0 .667.298.667.667s-.299.667-.667.667h-6v6c0 .368-.298.667-.667.667s-.667-.299-.667-.667v-6h-6C.964 8.667.666 8.369.666 8s.298-.667.667-.667h6v-6c0-.368.298-.667.667-.667z' fill='#{$accordion-icon-color}'/></svg>") !default;
$accordion-button-active-icon:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'><path fill-rule='evenodd' d='M.666 8c0-.368.298-.667.667-.667v1.333C.964 8.666.666 8.368.666 8zm14 0v.667H1.333V7.333h13.333V8zm0 0v.667c.368 0 .667-.298.667-.667s-.299-.667-.667-.667V8z' fill='#{$accordion-icon-active-color}'/></svg>") !default;


// Navs

$nav-link-padding-y:                .725rem !default;
$nav-link-padding-x:                1.75rem !default;
$nav-link-font-weight:              $font-weight-medium !default;
$nav-link-color:                    var(--#{$prefix}gray-900) !default;
$nav-link-hover-color:              var(--#{$prefix}gray-900) !default;
$nav-link-active-color:             var(--#{$prefix}gray-900) !default;
$nav-link-disabled-color:           var(--#{$prefix}gray-600) !default;
$nav-link-transition:               color .2s ease-in-out, background-color .2s ease-in-out, border-color .2s ease-in-out !default;


// Tabs

$nav-tabs-border-color:             transparent !default;
$nav-tabs-border-width:             var(--#{$prefix}border-width) !default;
$nav-tabs-border-radius:            var(--#{$prefix}border-radius) !default;
$nav-tabs-link-font-weight:         $font-weight-normal !default;
$nav-tabs-link-color:               $gray-900 !default;
$nav-tabs-link-hover-color:         $nav-link-hover-color !default;
$nav-tabs-link-active-color:        $nav-link-active-color !default;
$nav-tabs-link-hover-border-color:  transparent !default;
$nav-tabs-link-active-border-color: $primary !default;
$nav-tabs-link-active-bg:           transparent !default;
$nav-tabs-font-family:              $headings-font-family !default;


// List group

$list-group-border-color:           transparent !default;
$list-group-border-radius:          0 !default;
$list-group-item-padding-y:         $spacer !default;
$list-group-item-padding-x:         $spacer * 2 !default;
$list-group-item-border-radius:     $border-radius-pill !default;
$list-group-striped-order:          even !default;
$list-group-striped-color:          $gray-900 !default;
$list-group-striped-bg:             $gray-100 !default;


// Carousel

$carousel-nav-width:                 1rem !default;
$carousel-nav-height:                1rem !default;
$carousel-nav-bg:                    $gray-100 !default;
$carousel-nav-active-bg:             $accent !default;


// Dropdown

$dropdown-min-width:                 10rem !default;
$dropdown-border-color:              $gray-200 !default;
$dropdown-divider-bg:                $gray-200 !default;
$dropdown-divider-margin-y:          .375rem !default;
$dropdown-divider-margin-x:          1rem !default;
$dropdown-box-shadow:                $box-shadow-sm !default;
$dropdown-border-radius:             $border-radius-lg !default;

$dropdown-spacer:                    0 !default;

$dropdown-item-padding-y:            .375rem !default;
$dropdown-item-padding-x:            1rem !default;
$dropdown-item-font-size:            $font-size-sm !default;
$dropdown-item-icon-size:            $font-size-base !default;

$dropdown-font-size:                 $font-size-sm !default;

$dropdown-header-color:              $headings-color !default;
$dropdown-header-font-size:          $font-size-base !default;

$dropdown-link-color:                $gray-800 !default;
$dropdown-link-hover-color:          $primary !default;
$dropdown-link-hover-bg:             transparent !default;
$dropdown-link-active-color:         $primary !default;
$dropdown-link-active-bg:            transparent !default;
$dropdown-link-disabled-color:       $gray-600 !default;
$dropdown-link-transition:           all .2s ease-in-out !default;


// Navbar
// Toggler
$navbar-toggler-padding-y:           $spacer * .625 !default;
$navbar-toggler-padding-x:           $spacer * .25 !default;
$navbar-toggler-border-radius:       0 !default;
$navbar-toggler-bar-width:           1.375rem !default;
$navbar-toggler-bar-height:          .125rem !default;
$navbar-toggler-bar-spacing:         .3125rem !default;
