// List group

.list-group {
  --#{$prefix}list-group-item-border-radius: #{$list-group-item-border-radius};
  --#{$prefix}list-group-striped-color: #{$list-group-striped-color};
  --#{$prefix}list-group-striped-bg: #{$list-group-striped-bg};
}

.list-group-item {
  @include font-size($font-size-xl);
  @include border-radius(var(--#{$prefix}list-group-item-border-radius) !important);
  @include media-breakpoint-down(sm) {
    --#{$prefix}list-group-item-padding-y: #{$list-group-item-padding-y * .75};
    --#{$prefix}list-group-item-padding-x: #{$list-group-item-padding-x * .75};
    @include font-size($font-size-lg);
  }
}

.list-group-striped {
  > .list-group-item:nth-of-type(#{$list-group-striped-order}) {
    background-color: var(--#{$prefix}list-group-striped-bg);
  }
}
