//
// Font Icons
// Font generated using:
// https://icomoon.io/app
// --------------------------------------------------

// Define icon code variables
$bi-info: "\e901";
$bi-facebook: "\e900";
$bi-instagram: "\e902";
$bi-map-pin: "\e903";
$bi-medicine: "\e904";
$bi-phone: "\e905";
$bi-quality: "\e906";
$bi-star: "\e907";
$bi-telegram: "\e908";
$bi-whatsapp: "\e909";
$bi-wings: "\e90a";


// Icons styles
@font-face {
  font: {
    family: $icons-font-family;
    weight: normal;
    style: 400;
    display: block;
  }
  src: url("#{$icons-font-path}/#{$icons-font-family}.ttf?mx2kip") format("truetype"), url("#{$icons-font-path}/#{$icons-font-family}.woff?mx2kip") format("woff"), url("#{$icons-font-path}/#{$icons-font-family}.svg?mx2kip##{$icons-font-family}") format("svg");
}

[class^="bi-"],
[class*=" bi-"] {
  display: inline-block;

  /* use !important to prevent issues with browser extensions that change fonts */
  font: {
    family: "#{$icons-font-family}";
    style: normal;
    weight: 400;
    variant: normal;
  }
  speak: never;
  line-height: 1;
  text-transform: none;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bi-info {
  &::before {
    content: $bi-info;
  }
}
.bi-facebook {
  &::before {
    content: $bi-facebook;
  }
}
.bi-instagram {
  &::before {
    content: $bi-instagram;
  }
}
.bi-map-pin {
  &::before {
    content: $bi-map-pin;
  }
}
.bi-medicine {
  &::before {
    content: $bi-medicine;
  }
}
.bi-phone {
  &::before {
    content: $bi-phone;
  }
}
.bi-quality {
  &::before {
    content: $bi-quality;
  }
}
.bi-star {
  &::before {
    content: $bi-star;
  }
}
.bi-telegram {
  &::before {
    content: $bi-telegram;
  }
}
.bi-whatsapp {
  &::before {
    content: $bi-whatsapp;
  }
}
.bi-wings {
  &::before {
    content: $bi-wings;
  }
}
